<template>
	<div>
		<search-list-table
			ref="searchListTable"
			:columns="columns"
			:formSearchList="formSearchList"
			:initDataSource="initDataSource"
			:upfrontFeesRowSelection="potentialStatus === '0' ? upfrontFeesRowSelection : null"
		>
			<template slot="toggleTable">
				<a-tabs :activeKey="potentialStatus" @change="changeTab">
					<a-tab-pane key="0" :tab="$t('table.CustomerManagement.Intention.inProgress')"> </a-tab-pane>
					<a-tab-pane key="1" :tab="$t('table.CustomerManagement.Intention.converted')" force-render> </a-tab-pane>
					<!-- <a-tab-pane key="2" :tab="$t('table.CustomerManagement.Intention.invaidated')" force-render> </a-tab-pane> -->
					<div slot="tabBarExtraContent">
						<a-button
							v-if="potentialStatus === '0'"
							type="primary"
							:disabled="upfrontFeesSelectRow.length == 0"
							@click="showAssignModal(selectedRows)"
							>{{ $t('page.assignSalesman') }}</a-button
						>
						<a-button type="primary" style="margin-left: 20px" @click="exportSheet">
							{{ $t('page.download') }}
						</a-button>
					</div>
				</a-tabs>
			</template>
		</search-list-table>
		<a-modal v-model="assignModalShow" title="Assign" :destroyOnClose="true" width="600px" @ok="assignFollowCollector">
			<a-form-item label="Choose a Sales" class="send">
				<a-select v-model="followerNameSelect" mode="multiple">
					<a-select-option :value="item.id" v-for="item in marketersList" :key="item.id">{{ item.displayName }}</a-select-option>
				</a-select>
			</a-form-item>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import { apiPotentialPage, apiMarketersAndSales, apiPotentialAssign, apiPotentialData } from '@/api/application'
export default {
	data() {
		return {
			upfrontFeesSelectRow: [],
			upfrontFeesRowSelection: {
				fixed: true,
				columnWidth: 50,
				selectedRowKeys: [],
				onChange: (k, r) => {
					this.upfrontFeesRowSelection.selectedRowKeys = k
					this.upfrontFeesSelectRow = r
				}
			},
			assignModalShow: false,
			followerNameSelect: [],
			marketersList: [],
			potentialStatus: '0'
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.CustomerManagement.display_name',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.phone_number',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.CustomerManagement.fromdata.idNumber',
					type: 'input',
					validateField: ['nationalId']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['regNo']
				},
				{
					label: 'table.CustomerManagement.salesman_username',
					type: 'select',
					validateField: ['follower'],
					selectOption: this.marketersList,
					dataPreType: {
						value: 'id',
						text: 'displayName'
					}
				},

				{
					label: 'table.CustomerManagement.IntentionDetail.feedbackColumns.isVehicle',
					type: 'select',
					validateField: ['isValuated'],
					selectOption: ['0', '1'],
					prefix: 'table.CustomerManagement.fromdata.AmountObj.options'
				},
				{
					label: 'table.CustomerManagement.fromdata.ReportObj.hasReport',
					type: 'select',
					validateField: ['isStatemented'],
					selectOption: ['0', '1'],
					prefix: 'table.CustomerManagement.fromdata.ReportObj.options'
				}
			]
		},
		columns() {
			const columnsList = [
				{
					title: this.$t('table.CustomerManagement.create_time'),
					dataIndex: 'createTime',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.display_name'),
					dataIndex: 'fullName',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.IntentionDetail.gender'),
					dataIndex: 'sex',
					align: 'center',
					customRender: (v, o) => {
						return v ? this.$t(`table.CustomerManagement.IntentionDetail.gender_map.${v === 1 ? '0' : '1'}`) : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.phone_number'),
					dataIndex: 'mobile',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.Permission.User.email'),
					dataIndex: 'email',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.EmployeeManagement.Branchman.branchOffice'),
					dataIndex: 'officeName',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'nationalId',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.Clue.source_from'),
					dataIndex: 'source',
					align: 'center',
					customRender: (v, o) => {
						if (o.source === 'pc-online') {
							return v ? 'Web' : '-'
						} else if (o.source === 'mobile-online') {
							return v ? 'App' : '-'
						} else if (o.source === 'lead-cast') {
							return v ? 'Lead Converted' : '-'
						} else {
							return '-'
						}
					}
				},
				// {
				// 	title: 'keyword',
				// 	dataIndex: 'keyword',
				// 	align: 'center',
				// 	customRender: (v, o) => {
				// 		return v ? decodeURIComponent(v) : '-'
				// 	}
				// },
				{
					title: this.$t('table.applyInfo.vehicleLabel.vehicle'),
					dataIndex: 'make',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.vehicleLabel.model'),
					dataIndex: 'model',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.vehicleLabel.year'),
					dataIndex: 'yom',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.applyInfo.financialLabel.loanType'),
					dataIndex: 'loanType',
					align: 'center',
					customRender: (v, o) => {
						return v !== null ? ['Logbook Loans', 'Car Loans', 'Boda Boda Financing'][v] : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.IntentionDetail.expectLoanAmount'),
					dataIndex: 'expectLoanAmount',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				},
				{
					title: this.$t('table.CustomerManagement.IntentionDetail.feedbackColumns.isVehicle'),
					dataIndex: 'isValuated',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.CustomerManagement.fromdata.AmountObj.options.${o.isValuated}`)
					}
				},
				{
					title: 'Follower',
					dataIndex: 'followerName',
					align: 'center',
					customRender: (v, o) => {
						return v ? v : '-'
					}
				}
			]
			return this.potentialStatus === '0' ? columnsList : columnsList.filter((item) => item.dataIndex !== 'followerName')
		}
	},
	created() {
		this.$nextTick(() => {
			apiMarketersAndSales().then((result) => {
				this.marketersList = result
			})
		})
	},
	methods: {
		initDataSource(initParams) {
			return apiPotentialPage({ ...initParams, potentialStatus: this.potentialStatus })
		},
		changeTab(key) {
			this.potentialStatus = key
			this.$refs.searchListTable.initTable(1)
		},
		showAssignModal() {
			if (this.upfrontFeesSelectRow.length === 0) {
				this.$message.error('Please choose cases first')
				return false
			}
			this.followerNameSelect = []
			this.assignModalShow = true
		},
		assignFollowCollector() {
			if (this.followerNameSelect.length === 0) {
				this.$message.error('Please choose a Sales')
				return false
			}
			apiPotentialAssign({
				potentialIds: this.upfrontFeesSelectRow.map((item) => item.id), //线索id
				userIds: this.followerNameSelect //人员id
			}).then(() => {
				this.$message.success('Success')
				this.assignModalShow = false
				this.upfrontFeesRowSelection.selectedRowKeys = []
				this.upfrontFeesSelectRow = []
				this.followerNameSelect = []
				this.$refs.searchListTable.initTable(1)
			})
		},
		exportSheet() {
			const searchForm = { ...this.$refs.searchListTable.searchFormTable.getFieldsValue() }
			apiPotentialData(searchForm).then((res) => {
				let str = `Name,Gender,Mobile,Branch Office,National ID,Follower,Email,Loan Purpose,Valuated,Statements,Submit Time`
				res.forEach((item) => {
					str += `\r\n${item.fullName},${this.$t(`table.CustomerManagement.IntentionDetail.gender_map.${item.sex}`)},${item.mobile},${
						item.officeName
					},${item.nationalId},${item.followerName},${item.email},${item.loanPurpose},${this.$t(
						`table.CustomerManagement.fromdata.AmountObj.options.${item.isValuated}`
					)},${this.$t(`table.CustomerManagement.fromdata.ReportObj.options.${item.isStatemented}`)},${item.createTime}`
				})
				//encodeURIComponent解决中文乱码
				let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
				let link = document.createElement('a')
				link.href = uri
				link.download = `Potential Client.csv`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		}
	}
}
</script>

<style scoped lang="less">
.send {
	display: flex;
	margin-top: 20px;
	.ant-form-item-label {
		font-weight: 600;
	}
	.ant-input,
	.ant-select {
		width: 360px;
		height: 40px;
		line-height: 40px;
	}
}
</style>
